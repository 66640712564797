.custom-root {
    background-color: #fff;
}
  
.custom-divider {
    background-color: #000;
}
  
.custom-selected {
    background-color: #f00;
}