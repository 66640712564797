:root {
	/* Primary colors */
	--primary-light-yellow: #fff1ad;
	--primary-yellow: #FFDB2F;
	--primary-yellow-shadow: 0 0 0 .2rem rgba(255, 219, 47, .5);
	--primary-blue: #2CB9D9;
	--primary-blue-shadow: 0 0 0 .2rem rgba(44, 185, 217, .5);
	--primary-red: #EB4338;
	--primary-red-shadow: 0 0 0 .2rem rgba(235, 67, 56, .5);
	--primary-grey: #2D3840;
	--primary-grey-shadow: 0 0 0 .2rem rgba(45, 56, 64, .5);
	--primary-background: #f4f7fe;

	/* General colors */
	--text-color: #7d7d7d;
	--white: #ffffff;
	--black: #000000;
	--grey: rgba(125, 125, 125, 0.5);
	--lightgrey: #c5c5c5;
}

html,
body {
	min-height: 100%;
	overflow: auto;
}

a:link {
	color: var(--primary-blue);
}

body,
.header {
	color: var(--primary-grey);
	background-color: var(--primary-background);
	font-family: 'Roboto', sans-serif;
}

.table-responsive {
	background-color: white;
	border-radius: 15px;
	padding: 5px 10px 5px 10px;
}

.login-background {
	position: relative;
	top: 0;
	left: 0;
}

.bg-blue {
	background-color: var(--primary-blue);
}

.bg-lightblue {
	background-color: rgba(44, 185, 217, .4)
}

.login-logo {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 80%;
	padding: 10px;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
}

.sidebar.open {
	-webkit-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-ms-transition: all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease;
	left: 0;
}

.sidebar {
	-webkit-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-ms-transition: all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease;
}

.has-search .btn {
	border-radius: 7px;
	max-width: 100%;
	height: 50px;
	font-weight: 500;
	font-size: 17px;
	border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}

.has-search .form-control {
	border-radius: 7px;
	min-width: 50px;
	height: 50px;
	font-weight: 500;
	font-size: 17px;
	border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}

input.form-control,
.purhcase-order__doc {
	border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.has-search .form-control-feedback {
	color: var(--text-color);
	margin-top: 11px;
}

.btn-primary-lg {
	height: 50px;
	width: 50px;
	border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}

.btn-primary-lg:hover,
.btn-primary-lg:active {
	box-shadow: 0 0 0 0.2rem rgb(255 219 47 / 50%);
}

.btn-primary {
	background: var(--primary-yellow);
	border: 3px solid var(--primary-yellow);
	color: var(--white);
	border-radius: 7px;
	min-width: 175px;
	height: 50px;
	font-weight: 500;
	font-size: 17px;
	border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}

.btn-primary:hover {
	color: var(--primary-yellow);
	border: 3px solid var(--primary-yellow);
	background: var(--primary-white);
}

.btn-primary.focus,
.btn-primary:focus {
	box-shadow: var(--primary-yellow-shadow);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
	color: var(--primary-yellow);
	border: 2px solid var(--primary-yellow);
	background: transparent;
}

.btn-lg {
	font-size: 20px;
	line-height: 24px;
	height: 60px;
	display: block;
	width: 100%;
	height: 65px;
	border-radius: 15px;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	-ms-border-radius: 15px;
	-o-border-radius: 15px;
	font-weight: 700;
	font-size: 23px;
	line-height: 27px;
	cursor: pointer;
	transition: all 0.1s linear;
	-webkit-transition: all 0.1s linear;
	-moz-transition: all 0.1s linear;
	-ms-transition: all 0.1s linear;
	-o-transition: all 0.1s linear;
}

.btn-light {
	background: white;
	border: 3px solid white;
	color: var(--primary-grey);
	border-radius: 7px;
	min-width: 48px;
	height: 44px;
	font-weight: 500;
	font-size: 17px;
	border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}

.btn-light:hover {
	color: var(--primary-yellow);
	border: 3px solid var(--primary-yellow);
	background: white;
}

.btn-light:not(:disabled):not(.disabled).active, 
.btn-light:not(:disabled):not(.disabled):active, 
.show>.btn-light.dropdown-toggle {
	color: white;
	border: 3px solid var(--primary-yellow);
	background: var(--primary-yellow);
}

.btn-sm {
	height: 35px;
	font-size: 15px;
	border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
	padding: 1px 10px 3px 10px;
	min-width: 30px;
}

button:focus {
	outline: none;
}

.text-size-default {
	font-size: 17px;
}

.text-yellow {
	color: var(--primary-yellow);
}

.text-blue {
	color: var(--primary-blue);
}

.text-red {
	color: var(--primary-red);
}

.text-grey {
	color: var(--primary-grey);
}

.form-control-lg {
	width: 100%;
	height: 60px;
	font-size: 20px;
	line-height: 24px;
}

.form-control-lg {
	width: 100%;
	line-height: 26px;
	color: var(--black);
	background: var(--white);
	padding: 25px;
	border: 1px solid var(--grey);
	border-radius: 15px;
}

.forgot-password:hover {
	color: var(--primary-blue);
}

.password-wrapper {
	position: relative;
}

.password-eye {
	cursor: pointer;
	position: absolute;
	height: 30px;
	width: 30px;
	bottom: 0;
	right: 20px;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
}

.active-icon {
	color: var(--primary-blue);
}

table.dataTable thead th {
	/*padding: 10px 20px 10px 10px;*/
}

.dataTables_length select {
	width: 55px;
}

.dataTables_wrapper {
	overflow-x: visible;
}

.order-table__id {
	color: var(--primary-blue);
}

.menu__link-div {
	display: none;
}

.img-icon {
	height: 25px;
	width: 25px;
}

.menu__link--active,
.menu__link:hover {
	color: var(--primary-grey);
	font-weight: 400;
}

.menu__link--active a {
	color: var(--primary-grey);
}

.menu__link:hover .menu-link__icon--active {
	fill: var(--primary-grey);
}

.menu__link--active .menu-link__icon--active {
	fill: var(--primary-grey);
}

.nav .nav-item span {
    font-weight: 400;
}

.header__controls,
.sidebar {
	background-color: var(--white);
}

.menu__link--highlight {
	border: 1px solid var(--primary-yellow);
}

.content__header--with-line {
	padding: 0 30px 0 30px;
}

.header {
	background-color: var(--white);
}

.header__burger-btn span {
	background-color: var(--primary-grey);
}

.content__header {
	margin-top: 0px;
}

.sidebar {
	z-index: 999;
	overflow: hidden;
}

.sidebar__logo {
	margin: 0;
	padding: 35px;
	border-bottom: 3px solid var(--primary-background);
}

.header__logo-mob {
	margin-left: 15px;
}

.sidebar-footer {
	border-top: 3px solid var(--primary-background);
}

.delete-btn {
	border: 0;
	cursor: pointer;
	width: 40px;
	height: 40px;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
	background-color: var(--primary-red);
}

.delete-btn:hover,
.delete-btn:active { 
	box-shadow: 0 0 0 0.2rem rgba(235, 67, 56, .5);
}

.copy-btn {
	border: 0;
	cursor: pointer;
	width: 40px;
	height: 40px;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
	background-color: var(--primary-blue);
}

.copy-btn:hover,
.copy-btn:active { 
	box-shadow: 0 0 0 0.2rem rgba(44, 185, 217, .5);
}

.text-primary {
	color: var(--primary-yellow);
}

.yellow-btn-outline {
	border: 0;
	cursor: pointer;
	width: 40px;
	height: 40px;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
	color: var(--primary-grey);
	background-color: var(--primary-white);
	border: 3px solid var(--primary-yellow);
}

.yellow-btn-outline:hover {
	color: var(--primary-grey);
	border: 3px solid var(--primary-yellow);
	background: var(--primary-yellow);
}

.edit-btn {
	border: 0;
	cursor: pointer;
	width: 40px;
	height: 40px;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
	background-color: var(--primary-yellow);
}

.edit-btn:hover,
.edit-btn:active { 
	box-shadow: 0 0 0 0.2rem rgba(255, 219, 47, .5);
}

.custom-btn {
	display: flex;
	justify-content: center;
	align-items: center;
}

.btn_6month {
	min-width: fit-content !important;
}

.btn-icon {
	width: 0px;
	width: 50px;
	min-width: 50px;
	padding: 10px 10px 10px 10px;
}

.btn-icon-lg {
	height: 50px;
	width: 50px;
	min-width: 70px;
	padding: 10px 10px 10px 10px;
}

.btn-icon i,
.btn-icon-lg i {
	margin: 0;
}

.nav li {
	padding: 5px 15px 0 15px;
}

.nav li a,
.nav li .sidebar-submenu-div {
	display: flex;
	align-items: center;
	font-size: 20px;
	line-height: 23px;
	color: var(--primary-grey);
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	height: 50px;
	border-radius: 15px;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	-ms-border-radius: 15px;
	-o-border-radius: 15px;
	transition: all 0.1s linear;
	-webkit-transition: all 0.1s linear;
	-moz-transition: all 0.1s linear;
	-ms-transition: all 0.1s linear;
	-o-transition: all 0.1s linear;
}

.nav li a {
	font-weight: 400;
}

.nav li a:hover,
.nav li a.active,
.nav li a.menu__link--active {
	background: var(--primary-yellow);
	font-weight: 500;
	text-decoration: none;
}

.nav .nav-item span {
	font-size: 16px;
}

.nav-item {
	padding: 5px 0 0 0;
}

.sidebar-submenu_span {
	margin-left: 5px;
}

.sidebar-submenu_span i {
	font-size: 18px;
	color: black;
}

.flex-column .flex-column {
	padding-right: 0;
}

.header {
	display: none;
}

.p-20 {
	padding: 20px;
}

.sidebar {
	top: 0;
}

.header-logo {
	width: 230px;
}

.wide-button {
	width: 110px;
	padding: 2px;
	font-weight: 500;
}

.sidebar__logo {
	display: inline-block;
}

.order-booking__icon-active {
	color: var(--primary-blue);
}

.order-booking__icon-inactive {
	color: rgba(44, 185, 217, .6);
}

.sidebar {
	width: 280px;
}

.has-search .form-control {
	border: 0;
}

.has-search .form-control::placeholder {
	color: var(--text-color);
}

.form-control:focus {
	border: 1px solid var(--primary-yellow);
}
body {
	height: auto !important;
}

table.dataTable thead th {
	padding: 10px 20px 10px 10px;
}

textarea:focus, 
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
	box-shadow: none;
}

.select-show-class,
.dropdown-input {
	margin: 0;
}

.select-show-class > div {
	border-radius: 15px;
	-webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
	padding: 6px;
	border: 0;
}

.form-control {
	border-radius: 10px;
	-webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.dropdown-input > div {
	border-radius: 10px;
	-webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
	border: 0;
}

.select-show-class > div > div,
.dropdown-input > div > div {
	border-radius: 10px;
	-webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.border-grey {
	border: 1px solid var(--lightgrey);
	border-radius: 10px;
	-webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.dropdown-item:active {
	background-color: var(--primary-yellow);
	color: var(--primary-grey);
	font-weight: 500;
}

.css-1szy77t-control:hover {
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	color: #495057;
	background-color: #fff;
	border-color: var(--primary-yellow) !important;
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgb(255 219 47 / 50%) !important;
}

.css-1szy77t-control {
	box-shadow: 0 0 0 0.2rem rgb(255 219 47 / 50%) !important;
	border-color: var(--primary-yellow) !important;
}

.select-lang-class div div div:active {
	background-color: var(--primary-yellow);
	color: var(--primary-grey);
}

.select-lang-class div div {
	box-shadow: none;
}

.myDatePicker {
	border-radius: 10px;
}

.myDatePicker:focus {
	border: 1px solid var(--primary-yellow);
	box-shadow:0 0 0 0.2rem rgba(255, 219, 47, .5);
}

.prurprice-table__div {
	padding: 0;
}

.position-fixed {
	position: fixed;
	z-index: 9999;
	background-color: white;
	border-bottom: 1px solid #e1e1e1;
	width:inherit;
}

.table.dataTable thead .sorting {
	background-color: white;
}

@media (max-width: 768px) {
	.my-sm-2 {
		margin-bottom: 0.5rem!important;
		margin-top: 0.5rem!important;
	}
	
	.logo-sm {
		height: 200px;
	}

	.loginBackground {
		height: 200px;
		width: 100%;
	}
	
	.login-page label {
		font-size: 18px;
		line-height: 22px;
	}

	.login-page p {
		font-size: 18px;
		line-height: 22px;
		margin-bottom: 40px;
	}

	.w-sm-100 {
		width: 100%;
	}

	.forgot-password {
		font-size: 18px;
		line-height: 22px;
		color: var(--primary-blue);
	}

	.w-sm-33 {
		width: 33.33%
	}
}

@media (min-width: 768px) {
	.justify-content-md-end {
		justify-content: end;
	}

	.loginBackground {
		width: 100%;
		height: 100%;
	}

	.h-md-100 {
		height: 100%;
	}

	.login-page h1 {
		font-size: 40px;
	}

	.login-page p {
		font-size: 22px;
		line-height: 26px;
		font-weight: 400;
		margin-bottom: 50px;
	}

	.login-page label {
		font-size: 22px;
		line-height: 25px;
		font-weight: 400;
	}

	.login-page a {
		font-size: 20px;
		line-height: 24px;
	}

	.w-md-75 {
		width: 75%;
	}
}

@media (min-width: 1200px) {
	.side-bar__div {
		width: 280px;
	}

	.sidebar {
		position: relative;
		left: 0;
		top: 0;
		width: 100%;
		height: calc(100%);
		padding: 0;
		flex-basis: 320px;
		flex-shrink: 0;
		box-shadow: none;
	}
}

@media (max-width: 1440px) {
	.forgot-password {
		font-size: 20px;
		line-height: 24px;
		color: var(--primary-blue);
	}
}